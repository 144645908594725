import React from "react";

export default function WorkWithUs() {
  const whywork = [
    {
      icon: "/assets/images/career/5dayworking.svg",
      title: "5 days of working",
      alt: "5days-working-img",
      description:
        "We are having a very busy and tedious life cycle apart from that we also providing Saturday & Sunday off from weekend that you can also enjoy with friends and family that can improve the working productivity and life style. It will increase your performance day by day in our company.",
    },
    {
      icon: "/assets/images/career/work-life-balance.svg",
      title: "Work-life balance",
      alt: "work-life-balance-img",
      description:
        "We believe happy, balanced people who have time for their families and hobbies become more productive employees. Constantly working weekends are an unsustainable way of life.",
    },
    {
      icon: "/assets/images/career/career-growth-opportunity.svg",
      title: "Career growth opportunity",
      alt: "career-growth-opportunity-img",
      description:
        "We offer multiple career growth opportunities to improve their skills, knowledge and also provide a greater sense of responsibility for managing one's own career, which contributes to self-confidence.",
    },
    {
      icon: "/assets/images/career/employee-training.svg",
      title: "Employee training",
      alt: "employee-training-img",
      description:
        "Training is crucial for staying updated with the ever-evolving technologies, concepts, beliefs, and surroundings. By providing the required training, we want to support you. We don't just require skilled employees; we also make them skilled.        ",
    },
    {
      icon: "/assets/images/career/celebration.svg",
      title: "Recreational activity",
      alt: "recreational-activity-img",
      description:
        "we are encouraging our employees to move around and make fun. We also create space for employees to stay motivated and refreshed. We also celebrate the company's yearly anniversary, Birthdays and Religious celebrations.        ",
    },
  ];

  return (
    <>
      <div className="vc_row wpb_row vc_row-fluid vc_custom_1528805580898">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="wpb_text_column wpb_content_element">
                <div className="wpb_wrapper">
                  <h2
                    className="text-center about-company"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Why Work With Us?
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid pt-4 vc_custom_1529567857034">
            <div className="row">
              {whywork?.map((item, key) => {
                return (
                  <div
                    className="col-xl-4 col-md-6 mb-3 d-flex align-items-stretch"
                    key={key}
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <div className="shadow p-3 w-100">
                      <div className="card-body d-flex flex-column align-items-center text-align-center mkdf-iwt-inner">
                        <img
                          width="64"
                          height="64"
                          src={item?.icon}
                          className="attachment-full size-full"
                          alt={item?.alt}
                          title={item?.alt}
                          decoding="async"
                          loading="lazy"
                        />
                        <h3 className="fw-bold services-name hard-work-title">{item?.title}</h3>
                        <p className="mkdf-st-text font-size-line-height mb-4">
                          {item?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

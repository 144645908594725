import React from "react";
import { Link } from "react-router-dom";
import Canonical from "../common/Canonical";
import { PAGES_NAME } from "../constant/Label";

export default function PageNotFound() {
  return (
    <>
      <Canonical title={PAGES_NAME.PAGES_NOT_FOUND} />
      <div
        className="d-flex justify-content-center align-items-center text-center bg-dark-subtle"
        style={{
          height: "100vh",
        }}
      >
        <div>
          <h2>Page Not Found</h2>
          <Link to="/" className="text-body-emphasis h3">
            <i className="bi bi-arrow-left me-2"></i>
            <span className="text-decoration-underline">Back to Home</span>
          </Link>
        </div>
      </div>
    </>
  );
}

import React from "react";
import CarouselCard from "../../../common/CarouselCard";
import { Details } from "../../../constant/Details";
import Canonical from "../../../common/Canonical";
import { PAGES_NAME } from "../../../constant/Label";

export default function Clients() {
  const clientReview = [
    {
      image: "/assets/images/clients/user1.png",
      name: "Patil Pratik",
      position: "Team Leader",
      description: `What an awesome ${Details?.COMPANY_NAME} team, understood requirements easily, was very responsive, and maintained a professional attitude throughout. Done more than initially expected, and the work turned out great.`,
    },
    {
      image: "/assets/images/clients/user2.png",
      name: "Ash Srivastva",
      position: "Founder",
      description: `${Details?.COMPANY_NAME} team was able to complete the project on time and at budget. He's ability to converse and understand the requirements is also way above average and I would have no hesitation in using ${Details?.COMPANY_NAME} team in a future project.`,
    },
    {
      image: "/assets/images/clients/user3.png",
      name: "Jignesh Patel",
      position: "Project Leader",
      description:
        "An absolute pleasure to work with this professional team, very easy to communicate with and very very knowlegable, look forward to working with you again soon.",
    },
  ];

  return (
    <>
      <Canonical title={PAGES_NAME.OUR_CLIENTS} />
      <div className="mkdf-full-width">
        <div className="mkdf-full-width-inner">
          <div className="mkdf-grid-row">
            <div className="mkdf-page-content-holder mkdf-grid-col-12">
              <section className="wpb-content-wrapper">
                <div
                  className="vc_row wpb_row vc_row-fluid vc_row-o-content-middle vc_row-flex mkdf-content-aligment-center"
                  style={{ backgroundColor: "#f5f7ff" }}
                >
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768 mkdf-one-column-alignment-center">
                          <div
                            className="mkdf-eh-item mkdf-horizontal-alignment-center"
                            data-item-class="mkdf-eh-custom-2130"
                            data-1367-1600="00"
                            data-1025-1366="0px 146px"
                            data-769-1024="00"
                            data-681-768="00"
                            data-680="00"
                          >
                            <div className="mkdf-eh-item-inner">
                              <div
                                className="mkdf-eh-item-content mkdf-eh-custom-2130"
                                style={{ padding: "0px 0px 0px 142px" }}
                              >
                                <div
                                  className="mkdf-animation-holder mkdf-element-from-bottom"
                                  data-animation="mkdf-element-from-bottom"
                                  data-animation-delay
                                  data-aos="fade-up"
                                  data-aos-duration="2000"
                                >
                                  <div className="mkdf-animation-inner">
                                    <div className="mkdf-single-image-holder">
                                      <div className="mkdf-si-inner">
                                        <img
                                          width="1615"
                                          height="825"
                                          src="/assets/wp-content/uploads/2018/06/p7-title-img.png"
                                          className="attachment-full size-full"
                                          alt="client-main-img"
                                          title="client-main-img"
                                          decoding="async"
                                          loading="lazy"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mkdf-row-grid-section-wrapper">
                  <div className="mkdf-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1528282823964">
                      <div className="wpb_column vc_column_container vc_col-sm-12">
                        <div className="vc_column-inner">
                          <div className="wpb_wrapper">
                            <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768 mkdf-one-column-alignment-center">
                              <div
                                className="mkdf-eh-item mkdf-horizontal-alignment-center"
                                data-item-class="mkdf-eh-custom-9465"
                                data-1367-1600="0px 10px"
                                data-1025-1366="0px 220px"
                                data-769-1024="0px 70px"
                                data-681-768="0px 70px"
                                data-680="0px 37px"
                              >
                                <div className="mkdf-eh-item-inner">
                                  <div
                                    className="mkdf-eh-item-content mkdf-eh-custom-9465"
                                    style={{ padding: "0 25%" }}
                                  >
                                    <div className="mkdf-section-title-holder mkdf-st-standard mkdf-st-title-left mkdf-st-normal-space text-center">
                                      <div className="mkdf-st-inner">
                                        <h1
                                          className="mkdf-st-title fw-bolder about-company"
                                          data-aos="fade-up"
                                          data-aos-duration="1000"
                                        >
                                          Meet Our Amazing Clients
                                        </h1>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mkdf-row-grid-section-wrapper">
                  <div className="mkdf-row-grid-section">
                    <div className="vc_row wpb_row vc_row-fluid vc_custom_1530623714899">
                      <CarouselCard data={clientReview} />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

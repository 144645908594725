import React from "react";

export default function Chart() {
  const pieChartData = [
    {
      percent: "20",
      title: "Technologies",
      color: "#58468c",
    },
    {
      percent: "200",
      title: "Projects",
      color: "#5580ff",
    },
    {
      percent: "150",
      title: "Happy Clients",
      color: "#7bb9ff",
    },
    {
      percent: "6",
      title: "Years",
      color: "#6060A9",
    },
  ];
  
  return (
    <div className="mkdf-row-grid-section-wrapper">
      <div className="mkdf-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1529323114232">
          {pieChartData?.map((item, key) => {
            return (
              <div
                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-3 vc_col-md-6 vc_col-xs-12"
                key={key}
              >
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <div className="mkdf-pie-chart-holder">
                      <div className="mkdf-pie-chart-holder-inner">
                        <div
                          className="mkdf-pc-percentage"
                          data-percent={item?.percent}
                          data-bar-color={item?.color}
                        >
                          <span className="mkdf-pc-percent">
                            {item?.percent}
                          </span>
                        </div>
                        <div className="mkdf-pc-text-holder">
                          <h5 className="mkdf-pc-title fw-bold">
                            {item?.title}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Details } from "../../../constant/Details";

export default function Work() {
  const developmentContent = [
    {
      image: "assets/images/home/web-development.svg",
      name: "Web Development",
      alt: "web-development-img",
      description:
        "We bring your online vision to life. Our experts create stunning, user-friendly websites that work seamlessly across devices. Elevate your digital presence with us.",
    },
    {
      image: "assets/images/home/application-coding.svg",
      name: "Web Application",
      alt: "web-application-img",
      description:
        "We create tailored web applications that drive business success. Our team of experts combines design and development to bring your unique vision to life, making your online presence stand out and thrive. Transform your online capabilities with our web applications and stay ahead in the digital age.",
    },
    {
      image: "assets/images/home/mobile-app-developing.svg",
      name: "Mobile App Development",
      alt: "mobile-app-developing-img",
      description: `At ${Details?.COMPANY_NAME}, we specialize in turning your ideas into dynamic, user-friendly mobile applications. Join us on the path to mobile app success. Elevate your business, engage your audience, and stay at the forefront of the mobile revolution with our top-notch mobile app development services.`,
    },
  ];

  return (
    <div className="mkdf-row-grid-section-wrapper">
      <div className="mkdf-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1530525247134 vc_custom_1529567857034">
          <div className="wpb_column vc_column_container vc_col-sm-12">
            <div className="vc_column-inner">
              <div className="wpb_wrapper">
                <div className="mkdf-elements-holder mkdf-three-columns mkdf-responsive-mode-1024">
                  <div className="row">
                    {developmentContent?.map((item, key) => {
                      return (
                        <div
                          className="col-xl-4 col-md-6 mb-3 d-flex align-items-stretch"
                          key={key}
                          data-aos="zoom-in"
                          data-aos-duration="1000"
                        >
                          <div className="card shadow border-0">
                            <div className="card-body d-flex flex-column align-items-center text-align-center mkdf-iwt-inner">
                              <img
                                width="64"
                                height="64"
                                src={item?.image}
                                className="attachment-full size-full"
                                alt={item?.alt}
                                title={item?.alt}
                                decoding="async"
                                loading="lazy"
                              />
                              <h3 className="mb-0 work-name-title">{item?.name}</h3>
                              <p className="mkdf-st-text font-size-line-height mb-4">
                                {item?.description}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Details } from "../../../constant/Details";

export default function OurCoreValues() {
  const ourCoreValues = [
    {
      image: "/assets/images/about-us/integrity.svg",
      title: "Integrity",
      alt: "integrity-img",
      description:
        "Integrity involves adhering to a set of moral principles and being honest, trustworthy, and consistent in our thoughts, words, and actions. It encompasses qualities like sincerity, reliability, and ethical behavior.",
    },
    {
      image: "/assets/images/about-us/collaboration.svg",
      title: "Collaboration",
      alt: "collaboration-img",
      description: `${Details?.COMPANY_NAME} often work in teams, and fostering a culture of collaboration is essential. Encouraging open communication, knowledge sharing, and teamwork can enhance productivity, creativity, and problem-solving abilities.`,
    },
    {
      image: "/assets/images/about-us/quality-certificate.svg",
      title: "Quality",
      alt: "quality-img",
      description:
        "Striving for excellence and delivering high-quality products and services is a fundamental value. Setting and maintaining high standards ensures customer satisfaction, builds trust, and strengthens the company's reputation.",
    },
    {
      image: "/assets/images/about-us/innovative.svg",
      title: "Innovative",
      alt: "innovative-img",
      description:
        "Encouraging and fostering innovation is essential in the IT industry. Embracing new ideas, creative problem-solving, and staying at the forefront of technological advancements can drive the success and competitiveness of the company.",
    },
    {
      image: "/assets/images/about-us/collaboration.svg",
      title: "Transparency",
      alt: "transparency-img",
      description:
        "Transparency is at the core of our work philosophy, both in our client relationships and within our organization. We believe that open and honest communication builds trust and creates an environment where everyone can thrive and achieve their goals.",
    },
    {
      image: "/assets/images/about-us/quality-certificate.svg",
      title: "Accountability",
      alt: "quality-certificate-img",
      description:
        "Striving for excellence and delivering high-quality products and services is a fundamental value. Setting and maintaining high standards ensures customer satisfaction, builds trust, and strengthens the company's reputation.",
    },
  ];

  return (
    <>
      <div className="vc_row wpb_row vc_row-fluid vc_custom_1528805580898">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="wpb_text_column wpb_content_element">
                <div className="wpb_wrapper">
                  <h2
                    className="text-center about-company"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Our Core Values
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section border-radius-12 p-lg-2 p-md-2">
          <div className="vc_row wpb_row vc_row-fluid pt-4 vc_custom_1529567857034">
            <div className="row">
              {ourCoreValues?.map((item, key) => {
                return (
                  <div
                    className="col-xl-4 col-md-6 mb-3 d-flex align-items-stretch"
                    key={key}
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <div className="p-3 shadow w-100">
                      <div className="card-body d-flex flex-column align-items-center text-align-center mkdf-iwt-inner">
                        <img
                          width="64"
                          height="64"
                          src={item?.image}
                          className="attachment-full size-full"
                          alt={item?.alt}
                          title={item?.alt}
                          decoding="async"
                          loading="lazy"
                        />
                        <h3 className="mb-0 core-title">{item?.title}</h3>
                        <p className="mkdf-st-text font-size-line-height mb-4">
                          {item?.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

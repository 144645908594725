import React from "react";
import { Link } from "react-router-dom";

function Index({ navigate, classes, styles, title, target }) {
  return (
    <Link
      to={navigate}
      className={`btn-overlay ${classes}`}
      style={styles}
      target={target}
    >
      <span>{title}</span>
    </Link>
  );
}

export default Index;

import React from "react";

export default function People() {
  return (
    <div
      data-parallax-bg-image="/assets/images/home/people.jpeg"
      data-parallax-bg-speed="1"
      style={{
        backgroundImage: `url("/assets/images/home/people.jpeg")`,
        backgroundPosition: "50% 170px",
      }}
      className="vc_row wpb_row vc_row-fluid vc_row-o-content-middle vc_row-flex mkdf-parallax-row-holder mkdf-content-aligment-center"
    >
      <div className="wpb_column vc_column_container vc_col-sm-12">
        <div className="vc_column-inner">
          <div className="wpb_wrapper">
            <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768">
              <div
                className="mkdf-eh-item"
                data-item-class="mkdf-eh-custom-8966"
                data-1367-1600="300px 0"
                data-1025-1366="230px 0"
                data-769-1024="250px 0"
                data-681-768="210px 0"
                data-680="210px 0"
              >
                <div className="mkdf-eh-item-inner">
                  <div
                    className="mkdf-eh-item-content mkdf-eh-custom-8966"
                    style={{ padding: "412px 0" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

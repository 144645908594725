import React from "react";
import { Style } from "../../../common/Style";
import { Details } from "../../../constant/Details";
import Button from "../../../common/button";

export default function About() {
  return (
    <div className="mkdf-row-grid-section-wrapper vc_custom_1528805580898">
      <div className="mkdf-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="mkdf-eh-item-inner">
            <div className="mkdf-eh-item-content mkdf-eh-custom-2383">
              <div className="d-flex">
                <div className="col-md-12">
                  <div className="d-lg-flex justify-content-between align-items-center">
                    <div
                      className="col-lg-6 col-md-12"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <img
                        width="511"
                        height="345"
                        src="/assets/images/home/about-company.svg"
                        className="attachment-full size-full"
                        alt="about-company-img"
                        title="about-company-img"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div>
                        <h2
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          className="about-company"
                        >
                          About Our Company
                        </h2>
                        <p
                          className="mkdf-st-text font-size-line-height"
                          data-aos="zoom-in"
                          data-aos-duration="1000"
                        >
                          {Details?.COMPANY_NAME} is a global IT solution
                          provider company in surat with +6 years of competitive
                          business experience in niche industries. We offer
                          custom development, backend development, enterprise
                          services, and CMS website development. We have also
                          experienced professionals for native iOS, and Android
                          mobile applications & cross-platform technology such
                          as flutter and react native. We provide end-to-end
                          support for all our projects. Satisfied clients around
                          the globe bear testimony to the quality of our work.
                        </p>
                      </div>
                      <div className="pt-4" data-aos="fade-left">
                        <Button
                          navigate="/about-us"
                          classes="text-white"
                          styles={Style.moreButton}
                          title={"Read More"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

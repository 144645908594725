import React from "react";

export default function Industries() {
  return (
    <>
      <div className="vc_row wpb_row vc_row-fluid">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="wpb_text_column wpb_content_element">
                <div className="wpb_wrapper text-center">
                  <h2
                    className="mb-0 about-company"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    Industries
                  </h2>
                  <p
                    className="mkdf-st-text font-size-line-height mt-0"
                    data-aos="zoom-out-left"
                    data-aos-duration="1000"
                  >
                    We have domain expertise in the following enlisted fields :
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid pt-5 vc_custom_1529567857034">
            <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-12 vc_col-md-12">
              <div className="vc_column-inner vc_custom_1530709709723">
                <div className="wpb_wrapper">
                  <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768">
                    <ul className="mkdf-st-text font-size-line-height">
                      <div className="vc_col-lg-4 vc_col-md-6 vc_col-sm-12">
                        <li data-aos="zoom-out-right" data-aos-duration="500">
                          Medical
                        </li>
                        <li data-aos="zoom-out-right" data-aos-duration="600">
                          Marketing Communication Events
                        </li>
                        <li data-aos="zoom-out-right" data-aos-duration="700">
                          Tourism
                        </li>
                        <li data-aos="zoom-out-right" data-aos-duration="800">
                          Food industries
                        </li>
                      </div>
                      <div className="vc_col-lg-4 vc_col-md-6 vc_col-sm-12">
                        <li data-aos="zoom-out-right" data-aos-duration="500">
                          Finance
                        </li>
                        <li data-aos="zoom-out-right" data-aos-duration="600">
                          Health Care
                        </li>
                        <li data-aos="zoom-out-right" data-aos-duration="700">
                          E-commerce
                        </li>
                        <li data-aos="zoom-out-right" data-aos-duration="800">
                          Social Media
                        </li>
                      </div>
                      <div className="vc_col-lg-4 vc_col-md-6 vc_col-sm-12">
                        <li data-aos="zoom-out-right" data-aos-duration="500">
                          Education
                        </li>
                        <li data-aos="zoom-out-right" data-aos-duration="600">
                          Hospitality
                        </li>
                        <li data-aos="zoom-out-right" data-aos-duration="700">
                          Manufacturing
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import axios from "axios";
import CONFIG from "./config";

export const getTeams = (data) => {
  try {
    return axios
      .get(`${CONFIG.APIURL}api/v1/${data}`)
      .then((result) => {
        if (result?.status === 200) {
          return result?.data?.data;
        }
      })
      .catch((error) => {
        if(error?.response?.status === 404){
          return []
        }
      });
  } catch (error) {
    console.log("Error--->", error);
  }
};

import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Details } from '../constant/Details';

const Canonical = ({title}) => {
  const location = useLocation();
  const canonicalUrl = `${Details?.COMPANY_SITE_URL}${location.pathname.slice(1)}`;
  const comapnyTitle = title ? title + " | " + Details?.COMPANY_TITLE : Details?.COMPANY_TITLE;

  return (
    <div>
      <Helmet>
        <title>{comapnyTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
    </div>
  );
};

export default Canonical;

import React from "react";
import { Details } from "../../../constant/Details";

export default function MainContent() {
  return (
    <>
      <div
        data-parallax-bg-image="/assets/images/portfolio/aboutus-background.jpg"
        data-parallax-bg-speed="1"
        className="vc_row wpb_row vc_row-fluid vc_row-o-content-top vc_row-flex mkdf-parallax-row-holder mkdf-content-aligment-right"
        style={{
          backgroundColor: "#ebf0ff",
          backgroundImage:
            "url(/assets/images/portfolio/aboutus-background.jpg)",
          backgroundPosition: "50% 0px",
        }}
      >
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768 mkdf-one-column-alignment-right">
                <div
                  className="mkdf-eh-item mkdf-horizontal-alignment-right"
                  data-item-class="mkdf-eh-custom-3478"
                  data-1367-1600="150px 20px 0px 603px"
                  data-1025-1366="100px 20px 0px 560px"
                  data-769-1024="247px 15px 0px 499px"
                  data-681-768="150px 30px 248px 30px"
                  data-680="85px 0px 231px"
                >
                  <div className="mkdf-eh-item-inner">
                    <div
                      className="mkdf-eh-item-content mkdf-eh-custom-3478"
                      style={{ padding: "151px 50px 0px 669px" }}
                    >
                      <div
                        className="mkdf-animation-holder mkdf-element-from-bottom"
                        data-animation="mkdf-element-from-bottom"
                        data-animation-delay
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        <div className="mkdf-animation-inner">
                          <div className="mkdf-single-image-holder">
                            <div className="mkdf-si-inner">
                              <img
                                width="1183"
                                height="674"
                                src="/assets/wp-content/uploads/2018/06/aboutus-img0.png"
                                className="attachment-full size-full"
                                alt="about-us-img"
                                title="about-us-img"
                                decoding="async"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mkdf-row-grid-section-wrapper mkdf-content-aligment-right">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid vc_row-o-content-top vc_row-flex">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner vc_custom_1528281355667">
                <div className="wpb_wrapper">
                  <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768 mkdf-one-column-alignment-right">
                    <div
                      className="mkdf-eh-item"
                      data-item-class="mkdf-eh-custom-7135"
                      data-1367-1600="130px 56% 106px 0"
                      data-1025-1366="161px 56% 117px 0"
                      data-769-1024="180px 38% 110px 0"
                      data-681-768="270px 8% 0px"
                      data-680="280px 0px 0px"
                    >
                      <div className="mkdf-eh-item-inner">
                        <div
                          className="mkdf-eh-item-content mkdf-eh-custom-7135"
                          style={{ padding: "0 63% 290px 0" }}
                        >
                          <div className="mkdf-section-title-holder mkdf-st-standard mkdf-st-title-left mkdf-st-normal-space text-align-left">
                            <div className="mkdf-st-inner">
                              <h1
                                className="mkdf-st-title main-slider-title"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                              >
                                Career
                              </h1>
                              <p
                                className="mkdf-st-text font-size-line-height"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                              >
                                We believe that people are the most valuable
                                resource in any organization.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid">
            <div className="mkdf-eh-item-inner">
              <div
                className="mkdf-eh-item-content mkdf-eh-custom-2383"
                style={{ padding: "0 5% 3%" }}
              >
                <div className="mkdf-section-title-holder mkdf-st-standard mkdf-st-title-left mkdf-st-normal-space text-center">
                  <div className="mkdf-st-inner">
                    <p
                      className="mkdf-st-text font-size-line-height"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                    >
                      "Therefore, our goal is to create an environment at work
                      where people can use their talent and expertise to advance
                      their career. We always welcomed and nurtured new talents
                      in our organization because we don’t just look at your
                      CV’s experience but also at individual potential and
                      dedication. If you are eager to sharpen your skills,
                      {Details?.COMPANY_NAME} is the perfect place for
                      you. Share your resume at {Details?.careerAddress} ."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "./utils/PageNotFound";
import Layout from "./components/Layout/Layout";
import Home from "./components/Pages/Home";
import AboutUs from "./components/Pages/AboutUs";
import ContactUs from "./components/Pages/ContactUs";
import Services from "./components/Pages/Services";
import Clients from "./components/Pages/Clients";
import OurTeam from "./components/Pages/OurTeam";
import Profile from "./components/Pages/OurTeam/Profile";
import Career from "./components/Pages/Career";
import Portfolio from "./components/Pages/Portfolio";
import JobDetails from "./components/Pages/Career/JobDetails";

function App() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // Function to load the external script
    const loadScript = () => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "/assets/wp-content/themes/foton/assets/js/modules.min6fb3.js?ver=6.1.3";
      script.id = "foton-mikado-modules-js";

      document.head.appendChild(script);
    };
    // Load the script
    loadScript();

    // Select all images with a title attribute
    const images = document.querySelectorAll("img[title]");

    // Function to remove the title on hover
    const handleMouseOver = (e) => {
      e.currentTarget.dataset.title = e.currentTarget.getAttribute("title");
      e.currentTarget.removeAttribute("title");
    };

    // Function to restore the title after hover
    const handleMouseOut = (e) => {
      e.currentTarget.setAttribute("title", e.currentTarget.dataset.title);
    };

    // Add event listeners to each image
    images.forEach((img) => {
      img.addEventListener("mouseover", handleMouseOver);
      img.addEventListener("mouseout", handleMouseOut);
    });

    // Cleanup function to remove the script and event listeners
    return () => {
      // Remove the script
      const existingScript = document.getElementById("foton-mikado-modules-js");
      if (existingScript) {
        existingScript.remove();
      }
      
      // Remove event listeners from each image
      images.forEach((img) => {
        img.removeEventListener("mouseover", handleMouseOver);
        img.removeEventListener("mouseout", handleMouseOut);
      });
    };
  }, [location.pathname]);

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route exact path="/*" element={<PageNotFound />} />
        <Route element={<Layout />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/our-team" element={<OurTeam />} />
          <Route exact path="/profile/:name" element={<Profile />} />
          <Route exact path="/our-clients" element={<Clients />} />
          <Route exact path="/portfolio" element={<Portfolio />} />
          <Route exact path="/our-services" element={<Services />} />
          <Route exact path="/career" element={<Career />} />
          <Route exact path="/job/:name" element={<JobDetails />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;

export const Label = {
  JSD: "Junior Software Developer",
  BDE: "Business Development Executive",
  HR: "Human Resource",
  PA: "Personal Assistant",
  MSD: "Mean Stack Developer",
};

export const PAGES_NAME = {
  HOME_PAGE: "",
  ABOUT_US: "About Us",
  OUR_TEAM: "Our Team",
  OUR_CLIENTS: "Our Clients",
  PORTFOLIO: "Portfolio",
  SERVICES: "Services",
  CAREER: "Career",
  CONTACT_US: "Contact Us",
  PAGES_NOT_FOUND: "Page Not Found",
}
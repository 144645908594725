import React from "react";
import { Style } from "../../../common/Style";
import Button from "../../../common/button";

function Index({ item, home }) {
  return (
    <div className="d-flex">
      <div className="col-md-12 portfolio-main-slider">
        <div className="d-flex justify-content-between align-items-center portfoli-sec-slider">
          <div className="col-lg-6 col-md-12 portfolio-details">
            <h2 className="fa-2x m-0">{item.title}</h2>
            <p className="mkdf-st-text font-size-line-height">
              {item.description}
            </p>
            {!home && (
              <div className="pt-4">
                <Button
                  navigate={item.link}
                  classes="text-white"
                  styles={Style.moreButton}
                  title={"Visit"}
                  target="_blank"
                />
              </div>
            )}
          </div>
          <div className="col-lg-6 col-md-12">
            <img
              width="511"
              height="345"
              src={item?.image}
              className="attachment-full size-full"
              alt={item?.alt}
              title={item?.alt}
              decoding="async"
              loading="lazy"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;

const btnBackground = "#333399";

export const Style = {
  topBar: {
    background: btnBackground,
  },
  moreButton: {
    background: "#333399",
    borderRadius: "10px",
    padding: "10px 22px",
  },
  portfolioButton: {
    borderRadius: "10px",
    background: btnBackground,
    color: "#fff",
  },
  submitButton: {
    background: btnBackground,
    color: "#fff",
  },
  jobCardBackground: {
    background: "rgb(220 229 255 / 40%)",
    borderRadius: "4px",
    padding: "1rem 1.5rem",
  },
  jobCardResponseTime: {
    alignItems: "center",
    background: "rgb(51 51 153 / 2%)",
    border: "1px solid #333399",
    borderRadius: "6px",
    display: "flex",
    margin: "1.3rem 0 1rem",
    padding: "1rem",
  },
  jobCardBoltStyle: {
    color: "#333399",
    fontSize: "20px",
    paddingRight: "10px",
  },
  portfolioCardStyle: {
    boxShadow: "0 0 10px #7e7d7d",
    marginTop: "25px",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    width: "fit-content",
  },
  portfolioImageStyle: {
    padding: "7px",
    borderRadius: "0",
    background: btnBackground,
  },
  buttonStyle: {
    background: "#333399",
    color: "#fff",
  },
};

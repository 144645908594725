import React from "react";

export default function OurKeyFeatures() {
  const ourKeyFeatures = [
    {
      image: "/assets/images/services/expert-team.svg",
      name: "Expert Team",
      alt: "expert-team-img",
      description:
        "Emphasize the qualifications and expertise of your team members. Highlight their experience, certifications, and areas of specialization to showcase the depth of knowledge and skill available to clients.",
    },
    {
      image: "/assets/images/services/innovation-and-creativity.svg",
      name: "Innovation and Creativity",
      alt: "innovation-and-creativity-img",
      description:
        "Communicate your commitment to innovation and creativity in your solutions. Highlight how you stay updated with the latest industry trends, emerging technologies, and design practices to offer cutting-edge and forward-thinking solutions.",
    },
    {
      image: "/assets/images/services/client-satisfaction.svg",
      name: "Client Satisfaction",
      alt: "client-satisfaction-img",
      description:
        "Highlight your focus on client satisfaction and success. Showcase testimonials or case studies that demonstrate how your solutions have positively impacted your clients' businesses and helped them achieve their goals.",
    },
    {
      image: "/assets/images/services/long-term-support.svg",
      name: "Long-Term Support",
      alt: "long-term-support-img",
      description:
        "Communicate your commitment to providing ongoing support and maintenance to clients even after the project is completed. Mention your post-launch support services and the availability of service-level agreements (SLAs) to ensure long-term client satisfaction.",
    },
  ];

  return (
    <div className="mkdf-row-grid-section-wrapper vc_custom_1528282823964">
      <div className="mkdf-row-grid-section">
        <h3
          className="mkdf-st-title text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          Our Key Features
        </h3>
        <p
          className="mkdf-st-text text-center font-size-line-height"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          Discover the distinctive qualities that make us stand out from the
          competition. Experience our expert team, tailored solutions, and
          commitment to innovation, delivering exceptional results for your
          business
        </p>
        <div className="vc_row wpb_row vc_row-fluid pt-3">
          <div className="row">
            {ourKeyFeatures?.map((item, key) => {
              return (
                <div
                  className="col-lg-6 mb-3 d-flex align-items-stretch"
                  key={key}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <div className="card shadow border-0">
                    <div className="card-body d-flex flex-column mkdf-iwt-inner">
                      <img
                        width="64"
                        height="64"
                        src={item?.image}
                        className="attachment-full size-full"
                        alt={item?.alt}
                        title={item?.alt}
                        decoding="async"
                        loading="lazy"
                      />
                      <h4 className="mb-0">{item?.name}</h4>
                      <p className="mkdf-st-text font-size-line-height mb-4">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export const Details = {
  hrefPhoneNumber: "+918320037878",
  phoneNumber: "+91 (832) 003-7878",
  emailAddress: "info@3elixir.com",
  careerAddress: "career@elitistsoftwaresolutionsllp.com",
  salesAddress: "sales@elitistsoftwaresolutionsllp.com",

  COMPANY_NAME: "ELITIST SOFTWARE SOLUTIONS LLP",

  FACEBOOK_URL: "https://www.facebook.com/3elitist/",
  LINKEDIN_URL: "https://www.linkedin.com/company/3elitist/",
  INSTAGRAM_URL: "https://www.instagram.com/elitistsoftwaresolutionsllp",

  COPY_WRITE: "2023",
  COMPANY_SITE_URL: "https://elitistsoftwaresolutionsllp.com/",
  COMPANY_TITLE: "Best Web & Mobile App Development company in Surat | ELITIST",

  COMPANY_ADD1: "310, Soham Arcade,",
  COMPANY_ADD2: "Nr. Baghban Circle,",
  COMPANY_ADD3: "Green City Road, Pal",
  COMPANY_PIN: "Surat, 395009",

  CONTACT_RESPONSE: `Success ! We will contact you in 24 hours`,
  CONTACT_ERROR: `Something went wrong. Please try again`,
  FILE_SELECTION_ERROR: `Please select only doc,docx and pdf file types`,
  JOB_POST_RESPONSE: `Thank you for apply this job post. We will get back to you in 24 hours`,
  READ_MORE: `Read More`,
  BOOK_CONSULTATION: `Book consultation`,
  CONSULTATION_HEADING: `Consultation`,
};

import React from "react";
import { Link } from "react-router-dom";
import { Details } from "../../constant/Details";

export default function Footer() {
  const technologyData = [
    {
      title: "TECHNOLOGY",
      items: ["ReactJS", "NodeJS", "Python", "HTML", "JavaScript"],
    },
  ];
  const quickLinkData = [
    {
      title: "QUICK LINKS",
      items: [
        { label: "Home", link: "/" },
        { label: "About Us", link: "/about-us" },
        { label: "Career", link: "/career" },
        { label: "Our Team", link: "/our-team" },
        { label: "Contact Us", link: "/contact-us" },
      ],
    },
  ];

  return (
    <footer className="mkdf-page-footer mkdf-footer-skin-light">
      <div className="mkdf-footer-top-holder">
        <div className="mkdf-footer-top-inner mkdf-grid">
          <div className="mkdf-grid-row mkdf-footer-top-alignment-left">
            <div className="mkdf-column-content mkdf-grid-col-4">
              <div
                id="media_image-2"
                className="widget mkdf-footer-column-1 widget_media_image mb-2"
              >
                <Link to="/">
                  <img
                    width="70"
                    height="26"
                    src="/assets/images/logo.png"
                    alt="logo-img"
                    title="elitist-logo"
                    decoding="async"
                    loading="lazy"
                  />
                </Link>
                {Details?.COMPANY_NAME}
              </div>
              <div
                id="text-2"
                className="widget mkdf-footer-column-1 widget_text"
              >
                <div className="textwidget">
                  <div>
                    Being a leading global provider of web applications and
                    mobile app development services to global brands,
                    Entrepreneurs, Digital agencies, and SMEs located around the
                    world, we focus on building long-term partnerships with
                    customers enabling them to reimagine their business through
                    a digital lens.
                  </div>
                  <div className="mt-4"></div>
                </div>
              </div>
            </div>

            {technologyData?.map((data, index) => {
              return (
                <div
                  className="mkdf-column-content mkdf-grid-col-2"
                  key={index}
                >
                  <div
                    id="text-3"
                    className="widget mkdf-footer-column-2 widget_text mb-0"
                  >
                    <div className="mkdf-widget-title-holder">
                      <h5 className="mkdf-widget-title text-white">
                        {data?.title}
                      </h5>
                    </div>
                  </div>
                  {data?.items?.map((title, index) => {
                    return (
                      <div key={index}>
                        <div className="mkdf-icon-widget-holder mkdf-icon-has-hover footer-title">
                          <span className="mkdf-icon-text font-size-16">
                            {title}
                          </span>
                        </div>
                        <div className="widget mkdf-separator-widget">
                          <div className="mkdf-separator-holder clearfix mkdf-separator-center mkdf-separator-normal">
                            <div className="mkdf-separator"></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}

            {quickLinkData?.map((data, index) => {
              return (
                <div
                  className="mkdf-column-content mkdf-grid-col-2"
                  key={index}
                >
                  <div
                    id="text-3"
                    className="widget mkdf-footer-column-2 widget_text mb-0"
                  >
                    <div className="mkdf-widget-title-holder">
                      <h5 className="mkdf-widget-title text-white">
                        {data?.title}
                      </h5>
                    </div>
                  </div>
                  {data?.items?.map((title, index) => {
                    return (
                      <div key={index}>
                        <Link
                          to={title?.link}
                          className="mkdf-icon-widget-holder mkdf-icon-has-hover"
                          data-hover-color="#8973ff"
                        >
                          <span className="mkdf-icon-text font-size-16">
                            {title?.label}
                          </span>
                        </Link>

                        <div className="widget mkdf-separator-widget">
                          <div className="mkdf-separator-holder clearfix mkdf-separator-center mkdf-separator-normal">
                            <div className="mkdf-separator"></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}

            <div className="mkdf-column-content mkdf-grid-col-4">
              <div
                id="text-3"
                className="widget mkdf-footer-column-2 widget_text mb-0"
              >
                <div className="mkdf-widget-title-holder">
                  <h5 className="mkdf-widget-title">Contact Info</h5>
                </div>
              </div>
              <a
                className="mkdf-icon-widget-holder mkdf-icon-has-hover"
                data-hover-color="#8973ff"
                href="https://www.google.com/maps/dir//ELITIST+SOFTWARE+SOLUTIONS+LLP+B%2FH+Madhav+Atria,+Baghban+Circle+310+Soham+Arcade+Green+City+Rd,+Surat,+Gujarat+395009/@21.1901583,72.7762865,12z/data=!4m5!4m4!1m0!1m2!1m1!1s0x3be04d2b6c3cd2a5:0x928e81b5f57b115e"
                target="_blank"
                rel="noreferrer"
              >
                <span className="mkdf-icon-element icon_pin_alt font-size-16"></span>
                <div className="mkdf-icon-text font-size-16">
                  <div>{Details?.COMPANY_ADD1}</div>
                  <div>{Details?.COMPANY_ADD2}</div>
                  <div>{Details?.COMPANY_ADD3}</div>
                </div>
              </a>
              <div className="widget mkdf-separator-widget">
                <div className="mkdf-separator-holder clearfix mkdf-separator-center mkdf-separator-normal">
                  <div className="mkdf-separator"></div>
                </div>
              </div>
              <a
                className="mkdf-icon-widget-holder mkdf-icon-has-hover"
                data-hover-color="#8973ff"
                href="https://www.google.com/maps/dir//ELITIST+SOFTWARE+SOLUTIONS+LLP+B%2FH+Madhav+Atria,+Baghban+Circle+310+Soham+Arcade+Green+City+Rd,+Surat,+Gujarat+395009/@21.1901583,72.7762865,12z/data=!4m5!4m4!1m0!1m2!1m1!1s0x3be04d2b6c3cd2a5:0x928e81b5f57b115e"
                target="_blank"
                rel="noreferrer"
              >
                <span className="mkdf-icon-element icon_pushpin_alt font-size-16"></span>
                <span className="mkdf-icon-text font-size-16">
                  {Details?.COMPANY_PIN}
                </span>
              </a>
              <div className="widget mkdf-separator-widget">
                <div className="mkdf-separator-holder clearfix mkdf-separator-center mkdf-separator-normal">
                  <div className="mkdf-separator"></div>
                </div>
              </div>
              <a
                className="mkdf-icon-widget-holder mkdf-icon-has-hover"
                data-hover-color="#8973ff"
                href={`mailto:${Details?.careerAddress}`}
                target="_blank"
                rel="noreferrer"
              >
                <span className="mkdf-icon-element icon_mail_alt font-size-16"></span>
                <span className="mkdf-icon-text font-size-16">
                  <span
                    className="__cf_email__"
                    data-cfemail="85e3eaf1eaebc5f4eae1e0ecebf1e0f7e4e6f1ecf3e0abe6eae8"
                  >
                    {Details?.careerAddress}
                  </span>
                </span>
              </a>
              <div className="widget mkdf-separator-widget">
                <div className="mkdf-separator-holder clearfix mkdf-separator-center mkdf-separator-normal">
                  <div className="mkdf-separator"></div>
                </div>
              </div>
              <a
                className="mkdf-icon-widget-holder mkdf-icon-has-hover"
                data-hover-color="#8973ff"
                href={`mailto:${Details?.salesAddress}`}
                target="_blank"
                rel="noreferrer"
              >
                <span className="mkdf-icon-element icon_mail_alt font-size-16"></span>
                <span className="mkdf-icon-text font-size-16">
                  <span
                    className="__cf_email__"
                    data-cfemail="85e3eaf1eaebc5f4eae1e0ecebf1e0f7e4e6f1ecf3e0abe6eae8"
                  >
                    {Details?.salesAddress}
                  </span>
                </span>
              </a>
              <div className="widget mkdf-separator-widget">
                <div className="mkdf-separator-holder clearfix mkdf-separator-center mkdf-separator-normal">
                  <div className="mkdf-separator"></div>
                </div>
              </div>
              <a
                className="mkdf-icon-widget-holder mkdf-icon-has-hover"
                data-hover-color="#8973ff"
                href={`tel:${Details?.hrefPhoneNumber}`}
                target="_blank"
                rel="noreferrer"
              >
                <span className="mkdf-icon-element icon_phone"></span>
                <span className="mkdf-icon-text font-size-16">
                  {Details?.phoneNumber}
                </span>
              </a>
            </div>
          </div>

          <div className="widget mkdf-social-icons-group-widget mkdf-light-skin mkdf-circle-icons text-align-center pt-4">
            <a
              className="mkdf-social-icon-widget-holder mkdf-icon-has-hover"
              data-hover-color="#8973ff"
              href={`https://wa.me/${Details?.hrefPhoneNumber}`}
              target="_blank"
              rel="noreferrer"
              aria-label="contact with whatsapp"
            >
              <span className="mkdf-social-icon-widget fab fa-whatsapp"></span>
            </a>
            <a
              className="mkdf-social-icon-widget-holder mkdf-icon-has-hover"
              data-hover-color="#8973ff"
              href={`mailto:${Details?.careerAddress}`}
              target="_blank"
              rel="noreferrer"
              aria-label="contact with mail"
            >
              <span className="mkdf-social-icon-widget">
                <i className="bi bi-envelope"></i>
              </span>
            </a>
            <a
              className="mkdf-social-icon-widget-holder mkdf-icon-has-hover"
              data-hover-color="#8973ff"
              href={Details?.FACEBOOK_URL}
              target="_blank"
              rel="noreferrer"
              aria-label="connect with facebook"
            >
              <span className="mkdf-social-icon-widget fab fa-facebook-f"></span>
            </a>
            <a
              className="mkdf-social-icon-widget-holder mkdf-icon-has-hover"
              data-hover-color="#8973ff"
              href={Details?.LINKEDIN_URL}
              target="_blank"
              rel="noreferrer"
              aria-label="connect with linkedin"
            >
              <span className="mkdf-social-icon-widget fab">
                <i className="bi bi-linkedin"></i>
              </span>
            </a>
            <a
              className="mkdf-social-icon-widget-holder mkdf-icon-has-hover"
              data-hover-color="#8973ff"
              href={Details?.INSTAGRAM_URL}
              target="_blank"
              rel="noreferrer"
              aria-label="connect with instagram"
            >
              <span className="mkdf-social-icon-widget fab fa-instagram"></span>
            </a>
          </div>
        </div>
      </div>
      <div className="mkdf-footer-bottom-holder">
        <div className="mkdf-footer-bottom-inner mkdf-grid">
          <div className="mkdf-grid-row">
            <div className="mkdf-grid-col-12">
              <div
                id="text-5"
                className="widget mkdf-footer-bottom-column-1 widget_text"
              >
                <div className="textwidget">
                  <div>
                    © {Details?.COPY_WRITE}{" "}
                    <a
                      href={Details?.COMPANY_SITE_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {Details?.COMPANY_NAME}
                    </a>
                    , All Rights Reserved
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

import React from "react";

function Input({ name, onChange, value, placeholder }) {
  return (
    <input
      type="text"
      className="form-control border text-transform-none bg-white"
      name={name}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  );
}

export default Input;

import React from "react";
import Information from "./Information";
import MainContent from "./MainContent";
import Form from "./Form";
import Canonical from "../../../common/Canonical";
import { PAGES_NAME } from "../../../constant/Label";

export default function ContactUs() {
  return (
    <>
      <Canonical title={PAGES_NAME.CONTACT_US} />

      <div className="mkdf-full-width">
        <div className="mkdf-full-width-inner">
          <div className="mkdf-grid-row">
            <div className="mkdf-page-content-holder mkdf-grid-col-12">
              <section className="wpb-content-wrapper">
                <MainContent />

                <Form />

                <Information />
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { Details } from "../../../constant/Details";
import Button from "../../../common/button";

export default function TimeZone() {
  return (
    <div className="mkdf-row-grid-section-wrapper">
      <div className="mkdf-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="mkdf-eh-item-inner">
            <div className="mkdf-eh-item-content mkdf-eh-custom-2383">
              <div className="d-flex">
                <div className="col-md-12">
                  <div className="d-lg-flex justify-content-between">
                    <div
                      className="col-lg-5 col-md-12"
                      data-aos="fade-right"
                      data-aos-duration="2000"
                    >
                      <img
                        width="367"
                        height="550"
                        src="/assets/images/services/time-zone.jpg"
                        className="attachment-full size-full"
                        alt="time-zone-img"
                        title="time-zone-img"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div>
                        <h3 data-aos="fade-up" data-aos-duration="1000">
                          Time Zone
                        </h3>
                        <ul className="ul-list-style mkdf-st-text font-size-line-height">
                          <li data-aos="fade-left" data-aos-duration="500">
                            Providing you the benefit to collaborate & discuss
                            your needs, and progress with the team in the
                            overlapping hour Everyday. As well as sound
                            work-life balance to our employees to have high
                            efficiency & productivity at work.
                          </li>
                          <li data-aos="fade-left" data-aos-duration="600">
                            Having said, in case of{" "}
                            <b className="fw-bold">Urgency/Crisis Scenario</b>,
                            our team is here irrespective of any time zones,
                            holding our Integrity towards you and your company.
                            Due to which we are able to establish long term
                            relations with our clients and attain 95% client
                            retention ratio.
                          </li>
                          <li data-aos="fade-left" data-aos-duration="700">
                            The culture at {Details?.COMPANY_NAME}, not only
                            promotes a 100% client satisfaction, but also takes
                            utmost care(or focuses) for employee's growth.
                          </li>
                        </ul>
                        <Button
                          navigate="/contact-us"
                          classes="mkdf-btn mkdf-btn-large text-white mt-4 bg-elist"
                          title={"Discuss with the team"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

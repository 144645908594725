import React from "react";
import { Details } from "../../../constant/Details";

export default function VisionMission() {
  return (
    <div className="mkdf-row-grid-section-wrapper">
      <div className="mkdf-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="mkdf-eh-item-inner">
            <div className="mkdf-eh-item-content mkdf-eh-custom-2383 p-lg-5 p-md-5">
              <div className="d-flex">
                <div className="col-md-12">
                  <div className="d-lg-flex justify-content-between align-items-center p-lg-3 p-md-3">
                    <div className="col-lg-6 col-md-12">
                      <div>
                        <h2 className="about-company" data-aos="fade-up" data-aos-duration="1000">
                          Our Vision
                        </h2>
                        <ul className="ul-list-style mkdf-st-text font-size-line-height">
                          <li data-aos="fade-right" data-aos-duration="1000">
                            Empowering Tomorrow's Technology, Today. At{" "}
                            {Details?.COMPANY_NAME}, we're dedicated to
                            providing cutting-edge of knowledge, and support.
                            Our vision is to be the trusted source for all your
                            technology needs, making the digital world
                            accessible, secure, and innovative.
                          </li>
                          <li data-aos="fade-right" data-aos-duration="1000">
                            To empower our customers to achieve their goals, by
                            providing reliable and quality solutions with
                            technology and innovation.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="col-lg-5 col-md-12"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    >
                      <img
                        width="511"
                        height="345"
                        src="/assets/images/about-us/our-vision.jpeg"
                        className="attachment-full size-full"
                        alt="our-vision-img"
                        title="our-vision-img"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div
                    className="d-lg-flex justify-content-between align-items-center"
                    style={{
                      padding: "60px 0px 16px",
                    }}
                  >
                    <div
                      className="col-lg-5 col-md-12"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <img
                        width="511"
                        height="345"
                        src="/assets/images/about-us/our-mission.jpg"
                        className="attachment-full size-full"
                        alt="our-mission-img"
                        title="our-mission-img"
                        decoding="async"
                        loading="lazy"
                      />
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div>
                        <h2 className="about-company" data-aos="fade-up" data-aos-duration="1000">
                          Our Mission
                        </h2>
                        <ul className="ul-list-style mkdf-st-text font-size-line-height">
                          <li data-aos="fade-left" data-aos-duration="1000">
                            To help entrepreneurs and creative thinkers convert
                            their ideas into reality through the power of
                            emotional intelligence by providing world class
                            facilities to our team.
                          </li>
                          <li data-aos="fade-left" data-aos-duration="1000">
                            We deliver world-class solutions, we are also
                            committed to providing them at competitive rates. We
                            believe that top-tier services should be accessible
                            to all.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import MainSlider from "./MainSlider";
import About from "./About";
import Work from "./Work";
import People from "./People";
import Portfolio from "./Portfolio";
import OurServices from "./OurServices";
import Chart from "./Chart";
import JoinOurTeam from "./JoinOurTeam";
import Canonical from "../../../common/Canonical";
import { PAGES_NAME } from "../../../constant/Label";

export default function Home() {
  return (
    <>
      <style jsx="true">{`
        .mkdf-pie-chart-holder .mkdf-pc-percentage .mkdf-pc-percent:after {
          content: "+";
        }
      `}</style>

      <Canonical title={PAGES_NAME.HOME_PAGE} />
      
      <div className="mkdf-full-width">
        <div className="mkdf-full-width-inner">
          <div className="mkdf-grid-row">
            <div className="mkdf-page-content-holder mkdf-grid-col-12">
              <section className="wpb-content-wrapper">
                <MainSlider />

                <About />

                <Work />

                <People />

                <Portfolio />

                <OurServices />

                <Chart />

                <JoinOurTeam />
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

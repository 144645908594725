import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  PORTFOLIO,
  SampleNextArrow,
  SamplePrevArrow,
} from "../../../utils/config";
import PortfolioLeftSlide from "./Left";
import PortfolioRightSlide from "./Right";

export default function WorkList() {
  const [sliderActive, setSliderActive] = React.useState(false);
  const portfolioRef = React.useRef(null);
  const sliderRef = React.useRef(null);

  // Slick slider settings
  const settings = {
    autoplay: sliderActive, // Enable autoplay
    autoplaySpeed: 3000,
    pauseOnHover: true,
    infinite: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  // Use Intersection Observer to detect section visibility
  React.useEffect(() => {
    const portfolioNode = portfolioRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setSliderActive(true); // Activate the slider
        } else {
          setSliderActive(false); // Deactivate the slider
        }
      },
      { threshold: 0.5 } // 50% visibility threshold
    );

    if (portfolioNode) observer.observe(portfolioNode);

    return () => {
      if (portfolioNode) observer.unobserve(portfolioNode);
    };
  }, []);

  // Ensure slider autoplay refreshes when `sliderActive` changes
  React.useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay(); // Start autoplay manually
    }
  }, [sliderActive]);

  // Debounce function to avoid rapid re-triggers
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const handleSliderActivation = debounce(() => {
    if (sliderActive && sliderRef.current) {
      sliderRef.current.slickPlay(); // Start autoplay
    } else if (sliderRef.current) {
      sliderRef.current.slickPause(); // Stop autoplay if inactive
    }
  }, 300); // 300ms debounce to avoid frequent triggers

  // Use Intersection Observer to detect visibility changes
  React.useEffect(() => {
    const portfolioNode = portfolioRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => setSliderActive(entry.isIntersecting),
      { threshold: 0.5 } // 50% visibility threshold
    );

    if (portfolioNode) observer.observe(portfolioNode);

    return () => {
      if (portfolioNode) observer.unobserve(portfolioNode);
    };
  }, []);

  // Trigger handleSliderActivation whenever sliderActive changes
  React.useEffect(() => {
    handleSliderActivation();
  }, [sliderActive, sliderRef, handleSliderActivation]);

  return (
    <>
      <style jsx="true">
        {`
          .slick-arrow.slick-next,
          .slick-arrow.slick-prev {
            background-color: #428cff;
            height: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            opacity: 0.8;
            height: 30px;
            width: 30px;
          }

          .slick-arrow.slick-next:hover,
          .slick-arrow.slick-prev:hover {
            background-color: #2574f5;
            opacity: 1;
          }

          .slick-prev::before,
          .slick-next::before {
            display: none;
          }

          .arrows {
            height: 20px;
            width: 20px;
            margin: 1px;
          }
          .slick-prev {
            left: -30px;
          }
          .slick-next {
            right: -30px;
          }
          article:hover .title-text-visit {
            opacity: 1;
            z-index: 2;
          }
          .mkdf-portfolio-list-holder.mkdf-pl-standard-shader
            article:hover
            .mkdf-pli-image:after {
            border-radius: 0;
          }
          .title-text-visit {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            padding: 8px 16px;
            border-radius: 4px;
            background: rgba(0, 0, 0, 0.6);
            font-size: 2.5em;
            text-transform: uppercase;
            opacity: 0;
            transition-delay: 0.2s;
            transition-duration: 0.3s;
          }
          .left-zero {
            position: absolute;
            left: 0 !important;
          }
        `}
      </style>

      <div className="mkdf-row-grid-section-wrapper" ref={portfolioRef}>
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid pt-5 pb-5">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div
                    className="mkdf-portfolio-list-holder mkdf-grid-list mkdf-disable-bottom-space mkdf-pl-gallery mkdf-three-columns mkdf-normal-space mkdf-pl-standard-shader mkdf-pl-rounded-image mkdf-pl-pag-load-more mkdf-pl-has-filter"
                    data-type="gallery"
                    data-number-of-columns="three"
                    data-space-between-items="normal"
                    data-number-of-items="9"
                    data-image-proportions="square"
                    data-enable-fixed-proportions="no"
                    data-enable-image-shadow="no"
                    data-rounded-image="yes"
                    data-orderby="date"
                    data-order="ASC"
                    data-item-style="standard-shader"
                    data-content-bottom-margin="22px"
                    data-enable-title="yes"
                    data-title-tag="h4"
                    data-enable-category="yes"
                    data-enable-count-images="yes"
                    data-enable-excerpt="no"
                    data-excerpt-length="20"
                    data-pagination-type="load-more"
                    data-load-more-top-margin="0px"
                    data-filter="yes"
                    data-filter-order-by="name"
                    data-enable-article-animation="no"
                    data-portfolio-slider-on="no"
                    data-enable-loop="yes"
                    data-enable-autoplay="yes"
                    data-slider-speed="5000"
                    data-slider-speed-animation="600"
                    data-enable-navigation="yes"
                    data-enable-pagination="yes"
                    data-max-num-pages="4"
                    data-next-page="2"
                  >
                    <div className="mkdf-pl-filter-holder">
                      <div className="mkdf-plf-inner">
                        <Slider {...settings} ref={sliderRef}>
                          {PORTFOLIO?.map((item, index) => {
                            const isEven = index % 2 === 0; // Check if the index is even or odd

                            return (
                              <div className="slide" key={index}>
                                {isEven ? (
                                  // First and third slides: Image on left, text on right
                                  <PortfolioLeftSlide item={item} />
                                ) : (
                                  // Second slide: Text on left, image on right
                                  <PortfolioRightSlide item={item} />
                                )}
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

export default function Layout() {
  const { pathname } = useLocation();
  return (
    <div className="mkdf-wrapper">
      <div className="mkdf-wrapper-inner">
        <Header />
        <div
          id="mkdf-back-to-top"
          className="cursor-pointer"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <span className="mkdf-btt-wrapper">
            <svg
              className="mkdf-btt-svg-top"
              x="0px"
              y="0px"
              viewBox="0 0 40 40"
              style={{ enableBackground: "new 0 0 40 40" }}
              // xml:space="preserve"
            >
              <g className="mkdf-arrow">
                <path d="M20,30L20,30c-0.6,0-1-0.4-1-1V13c0-0.6,0.4-1,1-1h0c0.6,0,1,0.4,1,1v16C21,29.6,20.6,30,20,30z" />
                <path d="M26.4,16.4l-5.7-5.7c-0.4-0.4-1-0.4-1.4,0l-5.7,5.7c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0l4.9-4.9l4.9,4.9   c0.4,0.4,1,0.4,1.4,0C26.8,17.4,26.8,16.8,26.4,16.4z" />
              </g>
            </svg>
          </span>
        </div>
        <div
          className="mkdf-content"
          style={
            pathname === "/" ? { marginTop: "0" } : { marginTop: "-120px" }
          }
        >
          <div className="mkdf-content-inner">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

import React from "react";

export default function Iframe() {
  return (
    <div
      className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-6 vc_col-md-12"
      data-aos="fade-right"
      data-aos-duration="1000"
    >
      <div className="vc_column-inner">
        <div className="wpb_wrapper">
          <div className="mkdf-google-map-holder">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d119041.62761689104!2d72.69388483923967!3d21.190138329886263!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04d2b6c3cd2a5%3A0x928e81b5f57b115e!2sELITIST%20SOFTWARE%20SOLUTIONS%20LLP!5e0!3m2!1sen!2sin!4v1696325626338!5m2!1sen!2sin"
              width="100%"
              height="550"
              className="border-0"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

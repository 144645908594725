import React from "react";
import Iframe from "./Iframe";
import GetInTouch from "./GetInTouch";

export default function Form() {
  return (
    <div className="mkdf-row-grid-section-wrapper">
      <div className="mkdf-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid vc_custom_1529485575006 vc_row-o-content-middle vc_row-flex">
          <Iframe />
          <GetInTouch />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Details } from "../../../constant/Details";

export default function Information() {
  const contactData = [
    {
      icon: "fa fa-phone fa-3x",
      title: "CONTACT US",
      para1: `Call : ${Details?.phoneNumber}`,
      para2: `WP : ${Details?.phoneNumber}`,
    },
    {
      icon: "fa fa-envelope fa-3x",
      title: "EMAIL US",
      para1: `Job : ${Details?.careerAddress}`,
      para2: `Sales : ${Details?.salesAddress}`,
    },
    {
      icon: "fa fa-clock fa-3x",
      title: "HOURS OF OPERATION",
      para1: "Monday - Friday",
      para2: "9:30 AM - 7:00 PM",
    },
  ];
  return (
    <div className="mkdf-row-grid-section-wrapper">
      <div className="mkdf-row-grid-section">
        <div className="vc_row wpb_row vc_row-fluid">
          {contactData?.map((item, key) => {
            return (
              <div
                className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-4 vc_col-md-6 vc_col-xs-12"
                key={key}
                data-aos="zoom-in-right"
                data-aos-duration="1000"
              >
                <div className="vc_column-inner">
                  <div className="wpb_wrapper">
                    <div className="mkdf-iwt">
                      <div className="mkdf-iwt-inner clearfix mkdf-iwt-icon-top mkdf-iwt-icon-medium">
                        <div className="mkdf-iwt-icon">
                          <a itemProp="url" href="#" target="_self">
                            <i className={item?.icon} aria-hidden="true"></i>
                          </a>
                        </div>
                        <div className="mkdf-iwt-content pb-5">
                          <h3 className="mkdf-iwt-title core-title">
                            <a itemProp="url" href="#" target="_self">
                              <span className="mkdf-iwt-title-text">
                                {item?.title}
                              </span>
                            </a>
                          </h3>
                          <div className="mkdf-iwt-text">
                            <p>{item?.para1}</p>
                            <p>{item?.para2}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

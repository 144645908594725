const CONFIG = {
  APIURL: "https://api.3elixir.com:9002/",

  CONTACT_US_URL: "api/v1/savecontact",
  BOOK_CONSULTATION_URL: "api/v1/quoteuser",

  TEMPLATE_TYPE: [
    { name: "Web Development", value: "web-development" },
    { name: "Mobile Development", value: "mobile-development" },
    { name: "Consultation", value: "consultation" },
  ],
};
export default CONFIG;

export const PORTFOLIO = [
  {
    title: "Quirkplus Website",
    link: "https://quirkplus.com/",
    image: "/assets/images/portfolio/quirkplus-web.png",
    filter: "portfolio-category-social-media",
    alt: "quirkplus-home-img",
    description:
      "It is front end application for quirkplus. It is used for managing and monitoring music, task and users.",
  },
  {
    title: "Quirkplus: day planner app",
    link: "https://app.quirkplus.com/download",
    image: "/assets/images/portfolio/quirkplus-app.png",
    filter: "portfolio-category-social-media",
    alt: "quirkplus-app-img",
    description:
      "Quirkplus is an Day Planner App to manage daily task . QuirkPlus helps you stay organized by tracking tasks and goals, with AI-powered suggestions to boost productivity.",
  },
  {
    title: "Steev Website",
    link: "https://steevlive.com/",
    image: "/assets/images/portfolio/steev-web.png",
    filter: "portfolio-category-social-media",
    alt: "steev-home-img",
    description:
      "Bold inspirational talks. Thought provoking conversations. Happy moments. Live events. Brilliant ideas. Positivity tips. Success stories. Motivational speeches. Entertaining chat.",
  },
  {
    title: "Steev App",
    link: "https://play.google.com/store/apps/details?id=com.steev.live&hl=en_IN&gl=US",
    image: "/assets/images/portfolio/steev-app.png",
    filter: "portfolio-category-social-media",
    alt: "steev-app-img",
    description:
      "Steev App is an Entertainment app where users go-live / schedule an event. People can participate in an event .Anybody can share thair thought in app and people arround the world can listen",
  },
  {
    title: "Auction",
    link: "https://admin.rcfaadmin.com",
    image: "/assets/images/portfolio/auction.png",
    filter: "portfolio-category-finance",
    alt: "auction-img",
    description:
      "It may provide access to management tools or administrative features for RCFA-related operations, requiring valid credentials to proceed.",
  },
  {
    title: "Air Market",
    link: "https://airmarket.io/t",
    image: "/assets/images/portfolio/airMarket.png",
    filter: "portfolio-category-tourism",
    alt: "airmarket-img",
    description:
      "FLYSAFE by AirMarket Inc. is a data management platform for tracking and managing drone operations, including flights, equipment, locations, and maintenance. It helps users generate reports to stay compliant with regulatory authorities.",
  },
  {
    title: "Drives and Detours",
    link: "https://www.drivesanddetours.com/",
    image: "/assets/images/portfolio/drives-and-detours.png",
    filter: "portfolio-category-tourism",
    alt: "drives-and-detours-img",
    description:
      "Drives & Detours is the best place for driving enthusiasts to find, rate, and share their favorite driving roads. But it’s not just for sports cars and supercars. We have awesome drive ideas for just about anyone who likes taking a weekend drive and exploring new roads.",
  },
  {
    title: "Mcgl",
    link: "https://mcglonline.com/auth",
    image: "/assets/images/portfolio/mcgl-web.png",
    filter: "portfolio-category-finance",
    alt: "mcgl-web-img",
    description:
      "The MCGL Digital Library contains primarily Oklahoma and Texas Districts 9 & 10 well logs with assorted other data. We have many thousands of electric logs, mud logs and early vintage sample/strip logs.",
  },
  {
    title: "Sprive",
    link: "https://sprive.com/",
    image: "/assets/images/portfolio/sprive-app.png",
    filter: "portfolio-category-health-care",
    alt: "sprive-app-img",
    description:
      "Sprive Limited is an appointed representative of Connect IFA Ltd for mortgage services (441505) and Prepay Technologies Ltd which is an electronic money institution authorised by the Financial Conduct Authority under the Electronic Money Regulations 2011 (FRN 900010) for the issuing of electronic money which are authorised and regulated by the Financial Conduct Authority.",
  },
  {
    title: "Ilmiya",
    link: "https://Ilmiya.com",
    image: "/assets/images/portfolio/Ilmiya.png",
    filter: "portfolio-category-finance",
    alt: "ilmiya-img",
    description:
      "Ilmiya is an AI-powered education platform that personalizes the student journey, automatically optimizes learning outcomes, and proactively engages with learners at the the right time. Ilmiya is an Arabic book reading website. User can search or read up to 100000 unique and rare books.",
  },
  {
    title: "Queros",
    link: "https://qureos.com",
    image: "/assets/images/portfolio/queros-web.png",
    filter: "portfolio-category-finance",
    alt: "queros-web-img",
    description:
      "Our Client wants to provide a e-learning solution for colleges / students to instantly engage with one / multiple experts to develop job-relevant skills and can be also participate in projects sponsored by corporate partners to acquire experiential learning while getting paid.",
  },
  {
    title: "Surasole Medical",
    link: "https://play.google.com/store/apps/details?id=com.surasole.medical&hl=en_IN&gl=US",
    image: "/assets/images/portfolio/surasole-medical-app.png",
    filter: "portfolio-category-health-care",
    alt: "surasole-medical-app-img",
    description:
      "SuraSole is a smart insole developed from wearable sensors and the Internet of Things. Surasole Insole is designed to help medical staff or rehabilitation therapists and the patients track the rehabilitation progress without the need to visit the hospital.",
  },
  {
    title: "Techon",
    link: "https://teachon.org",
    image: "/assets/images/portfolio/techon.png",
    filter: "portfolio-category-finance",
    alt: "techon-img",
    description:
      "TeachOn enables freelance online teachers to accept Alipay and WeChat Pay payments quickly and securely. Accept payments from your Chinese students within seconds.",
  },
  {
    title: "3Elixir",
    link: "https://3elixir.com",
    image: "/assets/images/portfolio/elixir.png",
    filter: "portfolio-category-finance",
    alt: "elixir-img",
    description:
      "3Elixir Solutions is a global IT solution provider company in surat with +6 years of competitive business experience in niche industries. We offer custom development, backend development, enterprise services, and CMS website development.",
  },
];

export function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        background: "#333399",
        borderRadius: 50,
      }}
      onClick={onClick}
    >
      <i
        className="fas fa-angle-right"
        style={{ color: "#fff", fontSize: "20px" }}
      ></i>
    </div>
  );
}

export function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
        background: "#333399",
        borderRadius: 50,
      }}
      onClick={onClick}
    >
      <i
        className="fas fa-angle-left"
        style={{ color: "#fff", fontSize: "20px" }}
      ></i>
    </div>
  );
}

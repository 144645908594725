import React, { useState, useEffect } from "react";
import CarouselCard from "../../../common/CarouselCard";
import { getTeams } from "../../../utils/baseAPI";

export default function JoinOurTeam() {
  const [careerData, setCareerData] = useState([]);

  const fetchData = async () => {
    let career = await getTeams(`job/`);
    if (career) {
      setCareerData(career);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="vc_row wpb_row vc_row-fluid vc_custom_1528805580898">
        <div className="wpb_column vc_column_container vc_col-sm-12">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="wpb_text_column wpb_content_element">
                <div className="wpb_wrapper">
                  <h3 className="text-center">Join Our Team</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mkdf-row-grid-section-wrapper">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid pt-4 vc_custom_1529567857034">
            <CarouselCard data={careerData} />
          </div>
        </div>
      </div>
    </>
  );
}

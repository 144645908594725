import React from "react";
import { WhatsappIcon } from "react-share";
import ReactWhatsapp from "react-whatsapp";
import { Details } from "../../../../constant/Details";
import Loader from "../../../../utils/Loader";
import { Style } from "../../../../common/Style";

export default function JobApplyForm(props) {
  return (
    <div className="col-lg-4 col-md-12 h-100" style={Style.jobCardBackground}>
      <div className="widget mkdf-recent-post-widget">
        <div className="mkdf-widget-title-holder">
          <h4 className="mkdf-widget-title">Interested in this job?</h4>
        </div>
        <ul className="mkdf-recent-posts">
          <li className="mkdf-rp-item clearfix">
            <div>
              <div className="mkdf-rp-image">
                <img
                  width="auto"
                  height="auto"
                  src="https://3ecenter.s3.ap-south-1.amazonaws.com/Ramila+Suthar.PNG"
                  className="attachment-56x56 size-56x56 wp-post-image"
                  alt="person-img"
                  title="person-img"
                  decoding="async"
                  loading="lazy"
                  style={{
                    borderRadius: "50%",
                  }}
                />
              </div>
              <div className="mkdf-rp-text">
                <h6 className="text-capitalize text-body-secondary m-0">
                  Contact Person
                </h6>
                <h5 className="mkdf-rp-title mt-0">Ramila Luhar</h5>
                <span className="mkdf-rp-date text-capitalize fw-bold">
                  Hiring Specialist
                </span>
              </div>
            </div>
          </li>
        </ul>
        <div style={Style.jobCardResponseTime}>
          <div style={Style.jobCardBoltStyle}>
            <i className="fa fa-bolt"></i>
          </div>
          <div>
            <h5 className="m-0 fw-bold">Fast Response Time</h5>
            <p className="m-0">Avg. response time: 01 days</p>
          </div>
        </div>
        <form>
          <div className="mb-3">
            <label htmlFor="fullName" className="form-label fw-bold text-black">
              Full Name:
            </label>
            <input
              type="text"
              className="form-control border bg-white"
              id="fullName"
              aria-describedby="emailHelp"
              name="fullName"
              value={props?.inputValue?.fullName || ""}
              onChange={props?.handleInputChange}
              placeholder="Full Name"
              style={{
                textTransform: "none",
              }}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label fw-bold text-black">
              Your E-mail:
            </label>
            <input
              type="email"
              className="form-control border bg-white"
              id="email"
              name="email"
              aria-describedby="emailHelp"
              value={props?.inputValue?.email || ""}
              onChange={props?.handleInputChange}
              placeholder="Your Email"
              style={{
                textTransform: "none",
              }}
            />
          </div>

          <div className="mb-3">
            <label
              htmlFor="phoneNumber"
              className="form-label fw-bold text-black"
            >
              Phone Number:
            </label>
            <input
              type="text"
              className="form-control border bg-white"
              id="phoneNumber"
              name="phoneNumber"
              value={props?.inputValue?.phoneNumber || ""}
              onChange={props?.handleInputChange}
              placeholder="Phone Number"
              maxLength="10"
              onKeyPress={props?.handleKeyPress}
              style={{
                textTransform: "none",
              }}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="customFile"
              className="form-label fw-bold text-black"
            >
              Documents:
            </label>
            <input
              className="form-control border bg-white"
              type="file"
              id="customFile"
              onChange={props?.handleFileInput}
            />
          </div>
          {/* <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input cursor-pointer"
              id="privacyPolicy"
            />
            <label
              className="form-check-label cursor-pointer"
              htmlFor="privacyPolicy"
            >
              I agree to privacy policy
            </label>
          </div> */}
          <button
            type="submit"
            className="btn btn-secondary bg-secondary col-12 d-flex justify-content-center align-items-center"
            onClick={props?.aplyjob}
            disabled={props?.isLoading}
          >
            {props?.isLoading && <Loader />}
            Apply For Job
          </button>
        </form>

        <ul className="d-flex mt-5">
          <li>Apply For Job</li>
          <li className="ms-4 me-4">
            <ReactWhatsapp
              number={Details?.hrefPhoneNumber}
              message={`Greetings of the day ! \n Application for ${props?.listdata?.job_title} \n I am applying in your organization for the post of ReactJS Developer.\n ${props?.joblink} \n I hereby enclose my CV for your kind consideration and favour. \n Please do the needful and awaiting for your positive reply. Your prompt response is highly appreciable.`}
              className="p-0 border-0"
            >
              <WhatsappIcon size={30} />
            </ReactWhatsapp>
          </li>

          <li>
            <a
              href={`mailto:${Details?.careerAddress}?subject=${props?.listdata?.job_title}&body=Your message, including the link to this page`}
              data-rel="external"
            >
              <svg viewBox="0 0 64 64" width="30" height="30">
                <rect
                  width="64"
                  height="64"
                  rx="0"
                  ry="0"
                  fill="#7f7f7f"
                ></rect>
                <path
                  d="M17,22v20h30V22H17z M41.1,25L32,32.1L22.9,25H41.1z M20,39V26.6l12,9.3l12-9.3V39H20z"
                  fill="white"
                ></path>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

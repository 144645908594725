import React, { useState } from "react";
import swal from "sweetalert";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import CONFIG from "../../../utils/config";
import { Details } from "../../../constant/Details";
import Loader from "../../../utils/Loader";
import Input from "../../../common/Input";

function BookConsultation({ show, setShow }) {
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({
    name: "",
    companyName: "",
    email: "",
    type: CONFIG.TEMPLATE_TYPE.length > 0 ? CONFIG.TEMPLATE_TYPE[0].value : "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({ ...inputValue, [name]: value });
  };

  const handleClose = () => {
    setShow(false);
    setInputValue("");
  };

  const handleSaveButton = () => {
    var epattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var eresult = epattern.test(inputValue?.email);

    if (!inputValue?.name) {
      toast.dismiss();
      toast.error("Enter Name");
      return false;
    }
    if (!inputValue?.email) {
      toast.dismiss();
      toast.error("Enter Email");
      return false;
    }
    if (!eresult) {
      toast.dismiss();
      toast.error("Enter Valid Email");
      return false;
    }
    if (!inputValue?.companyName) {
      toast.dismiss();
      toast.error("Enter Company Name");
      return false;
    }
    let data = {
      name: inputValue?.name,
      companyName: inputValue?.companyName,
      email: inputValue?.email,
      type: inputValue?.type,
    };
    consultationDataAPI(data);
  };

  const consultationDataAPI = (data) => {
    if (data) {
      setIsLoading(true);
      axios
        .post(CONFIG.APIURL + CONFIG.BOOK_CONSULTATION_URL, data)
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);
            swal(Details?.CONTACT_RESPONSE);
            handleClose();
          }
        })
        .catch((err) => {
          setIsLoading(false);
          swal(Details?.CONTACT_ERROR);
        });
    }
  };

  const formSelectStyle = {
    padding: "14px 30px",
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{Details?.CONSULTATION_HEADING}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <Input
            name="name"
            onChange={handleChange}
            value={inputValue?.name}
            placeholder="Name&nbsp;*"
          />
        </div>
        <div className="mb-3">
          <Input
            name="email"
            onChange={handleChange}
            value={inputValue?.email}
            placeholder="Email&nbsp;*"
          />
        </div>
        <div className="mb-3">
          <Input
            name="companyName"
            onChange={handleChange}
            value={inputValue?.companyName}
            placeholder="Company Name&nbsp;*"
          />
        </div>
        <select
          className="form-select border bg-white mb-5"
          autoComplete="off"
          name="type"
          onChange={handleChange}
          value={inputValue?.type}
          style={formSelectStyle}
        >
          {CONFIG.TEMPLATE_TYPE?.map((item, index) => (
            <option value={item.value} key={index}>
              {item.name}
            </option>
          ))}
        </select>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="d-flex bg-primary justify-content-center align-items-center border-success"
          onClick={handleSaveButton}
          disabled={isLoading}
        >
          {isLoading && <Loader />}
          <span className={isLoading ? "ms-2" : ""}>Submit</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default BookConsultation;

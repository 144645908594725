import React from "react";

export default function MainContent() {
  return (
    <>
      <div
        data-parallax-bg-image="/assets/images/contact-us-bg.png"
        data-parallax-bg-speed="1"
        className="vc_row wpb_row vc_row-fluid vc_row-o-content-bottom vc_row-flex mkdf-parallax-row-holder mkdf-content-aligment-right"
        style={{
          backgroundColor: "#ebf0ff",
          backgroundImage: "url(/assets/images/contact-us-bg.png)",
          backgroundPosition: "50% 0px",
        }}
      >
        <div className="wpb_column vc_column_container vc_col-sm-12 vc_col-lg-2 vc_col-md-2">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768 mkdf-one-column-alignment-right">
                <div
                  className="mkdf-eh-item mkdf-horizontal-alignment-right"
                  data-item-class="mkdf-eh-custom-5856"
                  data-1367-1600="404px 0 0 98px"
                  data-1025-1366="291px 0px 0px 88px"
                  data-769-1024="270px 0 0 65px"
                  data-681-768="28px 0 0 48px"
                  data-680="40px 110px 300px"
                >
                  <div className="mkdf-eh-item-inner">
                    <div
                      className="mkdf-eh-item-content mkdf-eh-custom-5856"
                      style={{ padding: "440px 0 0 110px" }}
                    >
                      <div
                        className="mkdf-animation-holder mkdf-element-from-left"
                        data-animation="mkdf-element-from-left"
                        data-animation-delay="300ms"
                      >
                        <div className="mkdf-animation-inner">
                          <div className="mkdf-single-image-holder">
                            <div className="mkdf-si-inner">
                              <img
                                width="173"
                                height="386"
                                src="/assets/wp-content/uploads/2018/06/ourprocess-background2.png"
                                className="attachment-full size-full"
                                alt="ourprocess-img"
                                title="ourprocess-img"
                                decoding="async"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wpb_column vc_column_container vc_col-sm-10">
          <div className="vc_column-inner">
            <div className="wpb_wrapper">
              <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768 mkdf-one-column-alignment-right">
                <div
                  className="mkdf-eh-item mkdf-horizontal-alignment-right"
                  data-item-class="mkdf-eh-custom-8433"
                  data-1367-1600="232px 75px 0 27%"
                  data-1025-1366="100px 75px 0 27%"
                  data-769-1024="160px 39px 0 34%"
                  data-681-768="112px 0px 0 7%"
                  data-680="0px"
                >
                  <div className="mkdf-eh-item-inner">
                    <div
                      className="mkdf-eh-item-content mkdf-eh-custom-8433"
                      style={{ padding: "232px 75px 0 21%" }}
                    >
                      <div
                        className="mkdf-animation-holder mkdf-grow-in"
                        data-animation="mkdf-grow-in"
                        data-animation-delay="100ms"
                      >
                        <div className="mkdf-animation-inner">
                          <div className="mkdf-single-image-holder">
                            <div className="mkdf-si-inner">
                              <img
                                width="1102"
                                height="590"
                                src="/assets/wp-content/uploads/2018/06/ourprocess-background1.png"
                                className="attachment-full size-full"
                                alt="our-services-img"
                                title="our-services-img"
                                decoding="async"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mkdf-row-grid-section-wrapper mkdf-content-aligment-right">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid vc_row-o-content-top vc_row-flex">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner vc_custom_1528987794970">
                <div className="wpb_wrapper">
                  <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-680 mkdf-one-column-alignment-center">
                    <div
                      className="mkdf-eh-item"
                      data-item-class="mkdf-eh-custom-4900"
                      data-1367-1600="420px 45% 106px 17%"
                      data-1025-1366="492px 47% 106px 9%"
                      data-769-1024="510px 34% 0px 69px"
                      data-681-768="160px 0 0px 27%"
                      data-680="415px 0px 0px"
                    >
                      <div className="mkdf-eh-item-inner">
                        <div
                          className="mkdf-eh-item-content mkdf-eh-custom-4900"
                          style={{ padding: "300px 60% 0px 6px" }}
                        >
                          <div className="mkdf-section-title-holder mkdf-st-standard mkdf-st-title-left mkdf-st-normal-space text-align-left">
                            <div className="mkdf-st-inner">
                              <h1
                                className="mkdf-st-title main-slider-title"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                              >
                                Services
                              </h1>
                              <p
                                className="mkdf-st-text font-size-line-height"
                                data-aos="fade-up"
                                data-aos-duration="1000"
                              >
                                We provide the best digital solutions
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

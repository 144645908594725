import React from "react";
import { Style } from "../../../common/Style";
import PortfolioLeftSlide from "../../Pages/Portfolio/Left";
import { PORTFOLIO } from "../../../utils/config";
import Button from "../../../common/button";

export default function Portfolio() {
  return (
    <>
      <div className="mkdf-row-grid-section-wrapper vc_custom_1528805580898">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid vc_custom_1529401716611">
            <div className="wpb_column vc_column_container vc_col-sm-12">
              <div className="vc_column-inner">
                <div className="wpb_wrapper">
                  <div className="mkdf-elements-holder mkdf-three-columns mkdf-responsive-mode-1024">
                    {PORTFOLIO?.slice(1, 2)?.map((item, key) => {
                      return (
                        <div className="pt-5 pb-5">
                          <PortfolioLeftSlide item={item} home={true} />
                        </div>
                      );
                    })}
                  </div>
                  <div className="d-flex justify-content-center pt-3 pb-3">
                    <Button
                      navigate="/portfolio"
                      classes="text-white p-2"
                      styles={Style.portfolioButton}
                      title={'View All Portfolio'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { Details } from "../../../../constant/Details";

export default function MainContent() {
  return (
    <div
      className="mkdf-title-holder mkdf-centered-type mkdf-title-va-window-top mkdf-preload-background mkdf-has-bg-image mkdf-bg-responsive"
      style={{
        backgroundImage:
          "url(/assets/wp-content/uploads/2018/06/p9-title-img.png)",
      }}
    >
      <div className="wpb_column vc_column_container vc_col-sm-12">
        <div className="vc_column-inner">
          <div className="wpb_wrapper">
            <div className="mkdf-elements-holder mkdf-one-column mkdf-responsive-mode-768 mkdf-one-column-alignment-center">
              <div
                className="mkdf-eh-item mkdf-horizontal-alignment-center"
                data-item-class="mkdf-eh-custom-2130"
                data-1367-1600="00"
                data-1025-1366="0px 146px"
                data-769-1024="00"
                data-681-768="00"
                data-680="00"
              >
                <div className="mkdf-eh-item-inner">
                  <div className="mkdf-eh-item-content mkdf-eh-custom-2130">
                    <div
                      className="mkdf-animation-holder mkdf-element-from-bottom"
                      data-animation="mkdf-element-from-bottom"
                      data-animation-delay
                    >
                      <div className="mkdf-animation-inner">
                        <div className="mkdf-single-image-holder">
                          <div className="mkdf-si-inner">
                            <img
                              width="1615"
                              height="825"
                              src="/assets/wp-content/uploads/2018/06/p9-title-img.png"
                              className="attachment-full size-full"
                              alt="profile-img"
                              title="profile-img"
                              decoding="async"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mkdf-title-wrapper">
                        <div className="mkdf-title-inner">
                          <div className="mkdf-grid mt-lg-5">
                            <h2 className="mkdf-page-title entry-title">
                              Profile
                            </h2>
                            <p className="d-xl-block d-none">
                              "Lead your professional career with{" "}
                              {Details?.COMPANY_NAME} !!"
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";

export default function WeOfferOurClients() {
  const weOfferOurClient = [
    {
      image: "/assets/images/about-us/execution-with-survey.svg",
      title: "Optimized Solution",
      description:
        "We provide you with software support and maintenance services that allow you to help your team create the maximum benefit of the innovative solution developed to resolve your business problems and generate higher returns.",
    },
    {
      image: "/assets/images/about-us/long-term-partnership.svg",
      title: "Long-Term Partnership",
      description:
        "We believe in a continuous and long term association to understand and support your evolving needs as your business grows, and allow you to gain continuous user engagement and returns.",
    },
    {
      image: "/assets/images/about-us/diverce-skill.svg",
      title: "Diverse skills",
      description:
        "We are constantly updated with latest market trends & technologies to provide complete support across all platforms and technologies.We have 350+ in-house diversed experienced Tech experts to fulfil your needs.",
    },
    {
      image: "/assets/images/about-us/fair-time-zone.svg",
      title: "Fair Time Zone",
      description:
        "We prefer to work in IST & in overlapping working hours. Providing you the benefit to collaborate & discuss in overlapping hour everyday as well as our employees with work-life balance to have optimum high efficiency at work.",
    },
  ];

  return (
    <div className="mkdf-row-grid-section-wrapper">
      <div className="mkdf-row-grid-section">
        <h2
          className="mkdf-st-title text-center pb-2 about-company"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          The Value We Offer Our Clients
        </h2>
        <div className="vc_row wpb_row vc_row-fluid">
          <div className="row">
            {weOfferOurClient?.map((item, key) => {
              return (
                <div
                  className="col-lg-6 mb-3 d-flex align-items-stretch"
                  key={key}
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <div className="card shadow border-0">
                    <div className="card-body d-flex flex-column mkdf-iwt-inner">
                      <img
                        width="64"
                        height="64"
                        src={item?.image}
                        className="attachment-full size-full"
                        alt="client-img"
                        title="client-img"
                        decoding="async"
                        loading="lazy"
                      />
                      <h3 className="mb-0 core-title">{item?.title}</h3>
                      <p className="mkdf-st-text font-size-line-height mb-4">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

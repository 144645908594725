import React from "react";
import { Details } from "../../../constant/Details";

export default function OurServices() {
  const ourServices = [
    {
      image: "/assets/images/services/application-development.svg",
      name: "Application Development",
      alt: "apk-development-img",
      description:
        `A trusted partner for programme delivery, The ${Details?.COMPANY_NAME} has an impressive track record of 96% on time delivery and 2.5 times less defect density ratio compared to the industry norms setby the Capers Jones quality metrics.`,
    },
    {
      image: "/assets/images/services/web-design.svg",
      name: "Custom Web Design",
      alt: "web-design-img",
      description:
        `${Details?.COMPANY_NAME} skill, understanding, knowledge, talent and experience to produce cutting edge visuals in web design and wireframes.`,
    },
    {
      image: "/assets/images/services/software-development.svg",
      name: "Software Development",
      alt: "software-development-img",
      description:
        "We are an associated enterprise with a software firm. We tend to invariably deliver success with a delegated approach, commitment, and dedication.",
    },
    {
      image: "/assets/images/services/quality-assurance.svg",
      name: "Quality Assurance",
      alt: "quality-assurance-img",
      description:
        "Digital enterprise customers benefit from an enriched end user experience through reliable software testing and quality assurance services, which are backed by innovative solutions.",
    },
    {
      image: "/assets/images/services/application-support.svg",
      name: "Application Support",
      alt: "application-support-img",
      description:
        "Enhance customer experience with optimised software performance by aligning your IT deliverables with business goals.",
    },
    {
      image: "/assets/images/services/ecommerce-services.svg",
      name: "Ecommerce Services",
      alt: "ecommerce-services-img",
      description:
        "Websites are good but E-commerce sites than earns are even better, We are here to turn your investment into profit making stage.",
    },
  ];
  
  return (
    <>
      <div className="mkdf-row-grid-section-wrapper vc_custom_1528282823964">
        <div className="mkdf-row-grid-section">
          <div className="vc_row wpb_row vc_row-fluid">
            <div className="row">
              {ourServices?.map((item, key) => {
                return (
                  <div
                    className="col-xl-4 col-md-6 mb-3 d-flex align-items-stretch"
                    key={key}
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <div className="card shadow border-0">
                      <div className="card-body d-flex flex-column align-items-center text-align-center mkdf-iwt-inner">
                        <img
                          src={item?.image}
                          className="attachment-full size-full"
                          alt={item?.alt}
                          title={item?.alt}
                          height={64}
                          width={64}
                          decoding="async"
                          loading="lazy"
                        />
                        <h2 className="fw-bold mb-0 services-name hard-work-title">{item?.name}</h2>
                        <p className="mkdf-st-text font-size-line-height mb-4">{item?.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
